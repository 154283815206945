import React, { useEffect, useState } from "react";
import  { useNavigate, Navigate } from 'react-router-dom'
import { baseUrl } from "../shared";
import '../pages/App.css';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import Snackbar from '@mui/material/Snackbar';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';

export function SiteContact() {
    const [company, setCompany] = useState ("1MAINT");
    const [site, setSite] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    var [companies, setCompanies] = useState([]);
    const [companiesLoaded, setCompaniesLoaded] = useState(false);
    var [siteLocations, setSiteLocations] = useState([]);
    const [siteLocationsLoaded, setSiteLocationsLoaded] = useState(false);
    const [viewUpdateContact, setViewUpdateContact] = useState(false);
    const navigate = useNavigate();
    
    const [loginStatus, setLoginStatus] = useState(false);
    const [createSiteContact] = useState(true);
    const [open, setOpen] = React.useState(false);
    const [errOpen, setErrOpen] = React.useState(false);
    const [message, setMessage] = useState('');
      const [toastMessage, setToastMessage] = useState('');


    const [toastState, setToastState] = React.useState({
      open: false,
      vertical: 'top',
      horizontal: 'center',
    });
    const { vertical, horizontal, toastOpen } = toastState;


    const handleToastClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setToastState({ ...toastState, toastOpen: false})
    }

    const handleClose = () => {
      setOpen(false);
      <Navigate to={'/admin/'} />
      {navigate(0)}
    };

    function updateContact() {
      if(viewUpdateContact) {
        setViewUpdateContact(false)
      } else
      { setViewUpdateContact(true)}
    }

    const handleName = (event) => {
      setName(event.target.value);
    };

    const handlePhone = (event) => {
      setPhone(event.target.value);
    };

    const handleEmail = (event) => {
      setEmail(event.target.value);
    };

    const handleErrClose = () => {
      setErrOpen(false);
    };

    useEffect(()=> {
      if (!localStorage.getItem('token')) {
        setLoginStatus(false);
      } else {
        setLoginStatus(true);
      }
    },[]);

const loadCompanies = async () => {
    const url = baseUrl + 'company/listAc/';
      await fetch(url, {
        method: 'GET',
        headers: {
            'username': localStorage.getItem('username'),
            'bToken': localStorage.getItem('bToken')
        }})
        .then((res) => {
          return res.json();
      }).then((json) => {
          console.log('companies ',json)
          setCompanies(json);
          console.log('companies named ',companies)
      })
      .catch((err) => {
        setCompaniesLoaded(false);
         console.log(err.message);
      });
    }

    useEffect(() => {
      if (!companiesLoaded ) {
        setCompaniesLoaded(true)
        loadCompanies()
      }
      if (!siteLocationsLoaded ) {
        setSiteLocationsLoaded(true)
        loadSiteLocations()
      }
    })


const loadSiteLocations = async () => {
              const url = baseUrl + 'asset/siteLocationAc/';
                await fetch(url, {
                  method: 'GET',
                  headers: {
                      'username': localStorage.getItem('username'),
                      'bToken': localStorage.getItem('bToken')
                  }})
                  .then((res) => {
                    return res.json();
                }).then((json) => {
                    console.log(json)
                    setSiteLocations(json);
                })
                .catch((err) => {
                  setSiteLocationsLoaded(false);
                   console.log(err.message);
                });
              }

const getSiteContact = async () => {
              console.log('comp ',company, ' site ',site)
              const selectedSite = {
                company: company,
                siteLocation: site,
              };
              const url = baseUrl + 'siteContact/Get';
                await fetch(url, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    'username': localStorage.getItem('username'),
                    'bToken': localStorage.getItem('bToken')
                  },
                  body: JSON.stringify(selectedSite),
                })
                  .then((res) => {
                    return res.json();
                }).then((json) => {
                    console.log('here ',json[0])
                    setName(json[0].name);
                    setPhone(json[0].phone);
                    setEmail(json[0].email);
                    setViewUpdateContact(true);
                    console.log('!! '+name)
                })
                .catch((err) => {
                  setSiteLocationsLoaded(false);
                   console.log(err.message);
                });
              }

  const cancelSiteContactSetup = () => {
    <Navigate to={'/admin/'} />
    {navigate(0)}
  };
  
      async function createSiteContactSetup(e) {
        if(company === '' || site === '' || name === '' || phone === '' || email === ''){
          console.log('fill in the fields!')
        } else {
          e.preventDefault();
          const siteContactInfo = {
            company: company,
            siteLocation: site,
            siteContact: name,
            siteContactPhone: phone,
            siteContactEmail: email,
          };
          const url = baseUrl + 'siteContact/addUpdate/';
          await fetch(url, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'username': localStorage.getItem('username'),
                  'bToken': localStorage.getItem('bToken')
              },
              body: JSON.stringify(siteContactInfo),
          })
              .then((response) => response.json())
                .then((data) => {
                  console.log(data[0])
                  if( data[0].status === 200) {
                  setToastMessage(data[0].message);
                  setToastState({ ...toastState, toastOpen: true, vertical: 'top', horizontal: 'center' })
                  handleClose()
                  } else if (data[0].status === 401) {
                    setToastMessage(data[0].message);
                    setToastState({ ...toastState, toastOpen: true, vertical: 'top', horizontal: 'center' })
                  }
                })
                .catch((err) => {
                  setToastMessage(err.message);
                  setToastState({ ...toastState, toastOpen: true, vertical: 'top', horizontal: 'center' })
                }); 
        }
      }

  
    return (
      <div className="admin">
<React.Fragment>
      <Dialog
        open={viewUpdateContact}
        onClose={updateContact}
      >
        <DialogTitle>View/Update Site Contact</DialogTitle>
        <DialogContent>
          <DialogContentText>
            View/Update Site contact for <br /> {company} ( {site} )
          </DialogContentText>
          <br />
          <InputLabel id="priority">Contact Name *</InputLabel>
          <TextField
            autoFocus
            multiline
            required
            margin="dense"
            id="name"
            name="name"
            label=""
            fullWidth
            variant="standard"
            placeholder={name}
            onChange={handleName}
          />
          <br />
          <InputLabel id="priority">Phone Number *</InputLabel>
          <TextField
            autoFocus
            multiline
            required
            margin="dense"
            id="name"
            name="phone"
            label=""
            fullWidth
            variant="standard"
            placeholder={phone}
            onChange={handlePhone}
          />
          <br />  
          <InputLabel id="priority">Email Address *</InputLabel>
          <TextField
            autoFocus
            multiline
            required
            margin="dense"
            id="name"
            name="email"
            label=""
            fullWidth
            variant="standard"
            placeholder={email}
            onChange={handleEmail}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelSiteContactSetup}>Cancel</Button>
          <Button onClick={createSiteContactSetup}>Add/Update</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>

        <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={toastOpen}
                autoHideDuration={2500}
                onClose={handleToastClose}
                message={toastMessage}
                key={vertical + horizontal}
              />
    

    { loginStatus && createSiteContact && (localStorage.getItem('canRegisterUser') === '1' || localStorage.getItem('canUpdateUser') === '1') ? (
      <div>
          <p>Find Site Contact</p>
          <FormControl
            margin="dense"
            fullWidth>
              <Autocomplete
                required
                disablePortal
                disableClearable
                options={companies}
                onChange={(e) => {setCompany(e.target.innerHTML);}}
              renderInput={(params) => <TextField {...params} label="Customer" />}
              />
          </FormControl>
          <FormControl
            margin="dense"
            fullWidth>
              <Autocomplete
                required
                disablePortal
                disableClearable
                options={siteLocations}
                onChange={(e) => {setSite(e.target.innerHTML);}}
              renderInput={(params) => <TextField {...params} label="Site Location" />}
              />
          </FormControl>
          <Button style={{minWidth: '100%'}} variant="contained" margin="dense" onClick={getSiteContact}> View/Update Site Contact</Button>
        </div>
        ) : null
      }
      <br />
      <br />
      </div>
    );
  }

export default SiteContact