import React, { useEffect, useState } from "react";
import  { useNavigate, Navigate } from 'react-router-dom'
import { baseUrl } from "../shared";
import '../pages/App.css';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export function UpdateUser() {
    const [changed, setChanged] = useState(false);
    var [userEdit, setUserEdit] = useState({});
    var [modUser, setModUser] = useState({});
    var [companies, setCompanies] = useState([]);
    const [companiesLoaded, setCompaniesLoaded] = useState(false);
    var [userList, setUserList] = useState([]);
    const [usersLoaded, setusersLoaded] = useState(false);
    const [userSelected, setUserSelected] = useState(false);
    var [nameKey, setNameKey] = useState();
    var [emailKey, setEmailKey] = useState();
    var [phoneKey, setPhoneKey] = useState();
    const navigate = useNavigate();
    const [checked, setChecked] = React.useState(true);
    
    const marks = [
      {
        value: 0,
        label: 'No',
      },
      {
        value: 1,
        label: 'Yes',
      },
    ];

    
    const [loginStatus, setLoginStatus] = useState(false);
    const [updateUser] = useState(true);
    const [open, setOpen] = React.useState(false);
    const [successOpen, setSuccessOpen] = React.useState(false);
    const [failOpen, setFailOpen] = React.useState(false);
    const [message, setMessage] = useState('');

    const handleClose = () => {
      setOpen(false);
    };

    const handleFailClose = () => {
      setFailOpen(false);
    };

    const handleProceedClose = (e) => {
      userUpdate(e)
      setOpen(false);
    };

    const handleSuccessClose = () => {
      <Navigate to={'/admin/'} />
      {navigate(0)}
    }

    const cancelUpdateUser = () => {
      <Navigate to={'/admin/'} />
      {navigate(0)}
    };

    useEffect(()=> {
      if (!localStorage.getItem('token')) {
        setLoginStatus(false);
      } else {
        setLoginStatus(true);
      }
    },[]);

    useEffect(() => {
      if (usersLoaded === false ) {
      const url = baseUrl + 'user/list/';
        fetch(url, {
          method: 'GET',
          headers: {
              'username': localStorage.getItem('username'),
              'bToken': localStorage.getItem('bToken')
          }})
          .then((res) => {
            return res.json();
        }).then((json) => {
            console.log(json)
            setUserList(json);
            setusersLoaded(true);
        })
        .catch((err) => {
           console.log(err.message);
        });
      }
      })

    useEffect(() => {
      if (companiesLoaded === false ) {
      const url = baseUrl + 'company/list/';
        fetch(url, {
          method: 'GET',
          headers: {
              'username': localStorage.getItem('username'),
              'bToken': localStorage.getItem('bToken')
          }})
          .then((res) => {
            return res.json();
        }).then((json) => {
            console.log(json)
            setCompanies(json);
            setCompaniesLoaded(true);
        })
        .catch((err) => {
           console.log(err.message);
        });
      }
      })

    useEffect(()=> {
        console.log(changed);
        console.log('Selected User ',userEdit);
        console.log('Modified User ',modUser);
    });

    async function userUpdate(e) {
        e.preventDefault();
        const url = baseUrl+'user/update';
        await fetch(url, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'username': localStorage.getItem('username'),
                'bToken': localStorage.getItem('bToken'),
                'selUserId': modUser.id
            },
            body: JSON.stringify(modUser),
        })
        .then((response) => response.json())
          .then((data) => {
            console.log(data)
            if( data.status === 200) {
            setMessage(data.message);
            setSuccessOpen(true);
            } else if (data.status === 401) {
              setMessage(data.message);
              setFailOpen(true);
            }
          })
          .catch((err) => {
            setMessage(err.message);
            setFailOpen(true);
          });
      }

    function userDetails(e) {
      const url = baseUrl+'user/details';
      fetch(url, {
          method: 'PATCH',
          headers: {
              'Content-Type': 'application/json',
              'username': localStorage.getItem('username'),
              'bToken': localStorage.getItem('bToken'),
              'selUserId': e
          },
          body: JSON.stringify(modUser),
      })
          .then((response) => {
              if (!response.ok) throw new Error('something went wrong');
              return response.json();
          })
          .then((data) => {
            setUserEdit(data[0]);
            setModUser(data[0]);
            setNameKey(`${Math.floor((Math.random() * 1000))}-min`);
            setPhoneKey(`${Math.floor((Math.random() * 1000))}-min`);
            setEmailKey(`${Math.floor((Math.random() * 1000))}-min`);
          });
    }

  
    return (
      <div className="admin">
        <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Confirm Update User
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
        <DialogActions>
          <Button onClick={handleProceedClose}>Proceed</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
        <React.Fragment>
      <Dialog
        open={successOpen}
        onClose={handleSuccessClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSuccessClose}>Ok</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
        <React.Fragment>
      <Dialog
        open={failOpen}
        onClose={handleFailClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFailClose}>Ok</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>

    { loginStatus && updateUser && localStorage.getItem('canUpdateUser') === '1' ? (
        <form className="registration">
          <p>Update Existing User</p>
          <FormControl
          margin="dense"
     fullWidth>
     <InputLabel shrink htmlFor="userNameTitle">
    UserName
  </InputLabel>
        <Select
          native
          label="Username"
          inputProps={{
            id: 'userNameTitle',
          }}
          fullWidth
          onChange={(e) => {
            setUserSelected(true);
            setModUser({
              ...modUser,
              selUserId: e.target.value,
           });
            userDetails(e.target.value);
            }}>
            <option selected disabled>Select Username to Modify</option>
          {
          userList.map((userList) => {
          return <option value={userList.id} key={userList.id}>{userList.userName}</option>
        })
      }
  </Select>
     </FormControl>

     { userSelected ? (
      <>
     <FormControl
          margin="dense"
     fullWidth>
     <InputLabel shrink htmlFor="companyTitle">
    Company *
  </InputLabel>
        <Select
          native
          label="Company   *"
          inputProps={{
            id: 'companyTitle',
          }}
          fullWidth
          onChange={(e) => {
            setChanged(true);
            setModUser({
               ...modUser,
               company: e.target.value,
            });
            }}>
            <option selected disabled>Current: {userEdit.company}</option>
          {
          companies.map((companies) => {
          return <option value={companies.company} key={companies.company}>{companies.description}</option>
        })
      }
  </Select>
     </FormControl>
        <Box>
         <TextField 
        fullWidth
          margin="dense"
          id="name" 
          label="Name" 
          variant="outlined" 
          key= {nameKey}
          value={modUser.name}
          type="text"  
          onChange={(e) => {
            setChanged(true);
            setModUser({
               ...modUser,
               name: e.target.value,
            });
            }}
        />
     </Box>
        <Box>
         <TextField 
        fullWidth
          margin="dense"
          id="email" 
          label="E-Mail Address" 
          key={emailKey}
          variant="outlined" 
          defaultValue={modUser.email}
          type="text"   
          onChange={(e) => {
            setChanged(true);
            setModUser({
               ...modUser,
               email: e.target.value,
            });
            }}
        />
     </Box>
        <Box>
         <TextField 
        fullWidth
          margin="dense"
          id="phone" 
          label="Phone Number" 
          variant="outlined" 
          key={phoneKey}
          defaultValue={modUser.phone}
          type="text" 
          onChange={(e) => {
            setChanged(true);
            setModUser({
               ...modUser,
               phone: e.target.value,
            });
            }}
        />
     </Box>
     <Box  sx={{ maxWidth: '85%' }}>
     <Typography left>User Is Active</Typography>
     <Slider 
  size="small"
  key={`${Math.floor((Math.random() * 1000))}-min`}
  defaultValue={ modUser.active }
  step={1}
  min={0}
  max={1}
  marks={marks}
  onChange={(e) => {
    setChanged(true);
    setModUser({
       ...modUser,
       active: e.target.value,
    });
    }}
/>
</Box>
     <Box  sx={{ maxWidth: '85%' }}>
     <Typography left>Can Use Asset Search</Typography>
     <Slider 
  size="small"
  key={`${Math.floor((Math.random() * 1000))}-min`}
  defaultValue={ modUser.canSearch }
  step={1}
  min={0}
  max={1}
  marks={marks}
  onChange={(e) => {
    setChanged(true);
    setModUser({
       ...modUser,
       canSearch: e.target.value,
    });
    }}
/>
</Box>
     <Box  sx={{ maxWidth: '85%' }}>
     <Typography left>Can Add Defects</Typography>
     <Slider 
  size="small"
  key={`${Math.floor((Math.random() * 1000))}-min`}
  defaultValue={userEdit.canAddDefect}
  value={modUser.canAddDefect}
  step={1}
  min={0}
  max={1}
  marks={marks}
  valueLabelDisplay="off" 
  onChange={(e) => {
    setChanged(true);
    setModUser({
       ...modUser,
       canAddDefect: e.target.value,
    });
    }}
/>
</Box>
     <Box  sx={{ maxWidth: '85%' }}>
     <Typography left>Can Create New Assets</Typography>
     <Slider 
  size="small"
  key={`${Math.floor((Math.random() * 1000))}-min`}
  defaultValue={userEdit.canAddAsset}
  value={modUser.canAddAsset}
  step={1}
  min={0}
  max={1}
  marks={marks}
  valueLabelDisplay="off" 
  onChange={(e) => {
    setChanged(true);
    setModUser({
       ...modUser,
       canAddAsset: e.target.value,
    });
    }}
/>
</Box>
     <Box  sx={{ maxWidth: '85%' }}>
     <Typography left>Can Edit Existing Assets</Typography>
     <Slider 
  size="small"
  key={`${Math.floor((Math.random() * 1000))}-min`}
  defaultValue={userEdit.canEditAsset}
  value={modUser.canEditAsset}
  step={1}
  min={0}
  max={1}
  marks={marks}
  valueLabelDisplay="off" 
  onChange={(e) => {
    setChanged(true);
    setModUser({
       ...modUser,
       canEditAsset: e.target.value,
    });
    }}
/>
</Box>
     <Box  sx={{ maxWidth: '85%' }}>
     <Typography left>Can Delete Existing Assets</Typography>
     <Slider 
  size="small"
  key={`${Math.floor((Math.random() * 1000))}-min`}
  defaultValue={userEdit.canDeleteAsset}
  value={modUser.canDeleteAsset}
  step={1}
  min={0}
  max={1}
  marks={marks}
  valueLabelDisplay="off" 
  onChange={(e) => {
    setChanged(true);
    setModUser({
       ...modUser,
       canDeleteAsset: e.target.value,
    });
    }}
/>
</Box>
     <Box  sx={{ maxWidth: '85%' }}>
     <Typography left>Can Inspect Assets</Typography>
     <Slider 
  size="small"
  key={`${Math.floor((Math.random() * 1000))}-min`}
  defaultValue={userEdit.canInspectAsset}
  value={modUser.canInspectAsset}
  step={1}
  min={0}
  max={1}
  marks={marks}
  valueLabelDisplay="off" 
  onChange={(e) => {
    setChanged(true);
    setModUser({
       ...modUser,
       canInspectAsset: e.target.value,
    });
    }}
/>
</Box>
     <Box  sx={{ maxWidth: '85%' }}>
     <Typography left>Can Add Attachments to Assets</Typography>
     <Slider 
  size="small"
  key={`${Math.floor((Math.random() * 1000))}-min`}
  defaultValue={userEdit.canAddAttachment}
  value={modUser.canAddAttachment}
  step={1}
  min={0}
  max={1}
  marks={marks}
  valueLabelDisplay="off" 
  onChange={(e) => {
    setChanged(true);
    setModUser({
       ...modUser,
       canAddAttachment: e.target.value,
    });
    }}
/>
</Box>
     <Box sx={{ maxWidth: '85%' }}>
     <Typography left>Can Create New Users</Typography>
     <Slider 
  size="small"
  key={`${Math.floor((Math.random() * 1000))}-min`}
  defaultValue={userEdit.canRegisterUser}
  value={modUser.canRegisterUser}
  step={1}
  min={0}
  max={1}
  marks={marks}
  valueLabelDisplay="off" 
  onChange={(e) => {
    setChanged(true);
    setModUser({
       ...modUser,
       canRegisterUser: e.target.value,
    });
    }}
/>
</Box>
     <Box  sx={{ maxWidth: '85%' }}>
     <Typography left>Can Edit/Update Users</Typography>
     <Slider 
  size="small"
  key={`${Math.floor((Math.random() * 1000))}-min`}
  defaultValue={userEdit.canUpdateUser}
  value={modUser.canUpdateUser}
  step={1}
  min={0}
  max={1}
  marks={marks}
  valueLabelDisplay="off" 
  onChange={(e) => {
    setChanged(true);
    setModUser({
       ...modUser,
       canUpdateUser: e.target.value,
    });
    }}
/>
</Box>
     <Box  sx={{ maxWidth: '85%' }}>
     <Typography left>Can Bulk Upload</Typography>
     <Slider 
  size="small"
  key={`${Math.floor((Math.random() * 1000))}-min`}
  defaultValue={userEdit.canBulkAdd}
  value={modUser.canBulkAdd}
  step={1}
  min={0}
  max={1}
  marks={marks}
  valueLabelDisplay="off" 
  onChange={(e) => {
    setChanged(true);
    setModUser({
       ...modUser,
       canBulkAdd: e.target.value,
    });
    }}
/>
</Box>
</>
      ) : null}
          <Button variant="contained" margin="dense" onClick={cancelUpdateUser}> Cancel User Update</Button>&nbsp;&nbsp;
          
          <Button variant="contained" margin="dense" disabled={!changed} onClick={setOpen}>Update User</Button>
        </form>
        
        ) : null
      }
      <br />
      <br />
      </div>
    );
  }

export default UpdateUser