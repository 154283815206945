import React, { useEffect, useState } from "react";
import  { useNavigate, Navigate } from 'react-router-dom'
import { baseUrl } from "../shared";
import '../pages/App.css';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export function Register() {
    const [usernameReg, setUsernameReg] = useState("");
    const [passwordReg, setPasswordReg] = useState ("");
    const [companyReg, setCompanyReg] = useState ("1MAINT");
    const [nameReg, setNameReg] = useState ("");
    const [emailReg, setEmailReg] = useState ("");
    const [phoneReg, setPhoneReg] = useState ("");
    const [canSearchReg, setCanSearchReg] = useState ("0");
    const [canAddAssetReg, setCanAddAssetReg] = useState ("0");
    const [canEditAssetReg, setCanEditAssetReg] = useState ("0");
    const [canDeleteAssetReg, setCanDeleteAssetReg] = useState ("0");
    const [canInspectAssetReg, setCanInspectAssetReg] = useState ("0");
    const [canAddAttachmentReg, setCanAddAttachmentReg] = useState ("0");
    const [canRegisterUserReg, setCanRegisterUserReg] = useState ("0");
    const [canUpdateUserReg, setCanUpdateUserReg] = useState ("0");
    const [canBulkAddReg, setCanBulkAddReg] = useState ("0");
    const [canAddDefect, setCanAddDefect] = useState ("0");
    var [companies, setCompanies] = useState([]);
    const [companiesLoaded, setCompaniesLoaded] = useState(false);
    const navigate = useNavigate();
    
    const marks = [
      {
        value: 0,
        label: 'No',
      },
      {
        value: 1,
        label: 'Yes',
      },
    ];

    
    const [loginStatus, setLoginStatus] = useState(false);
    const [createNewUser] = useState(true);
    const [open, setOpen] = React.useState(false);
    const [errOpen, setErrOpen] = React.useState(false);
    const [message, setMessage] = useState('');

    const handleClose = () => {
      setOpen(false);
      <Navigate to={'/admin/'} />
      {navigate(0)}
    };

    const handleErrClose = () => {
      setErrOpen(false);
    };

    useEffect(()=> {
      if (!localStorage.getItem('token')) {
        setLoginStatus(false);
      } else {
        setLoginStatus(true);
      }
    },[]);

    useEffect(() => {
      if (companiesLoaded === false ) {
      const url = baseUrl + 'company/list/';
        fetch(url, {
          method: 'GET',
          headers: {
              'username': localStorage.getItem('username'),
              'bToken': localStorage.getItem('bToken')
          }})
          .then((res) => {
            return res.json();
        }).then((json) => {
            console.log(json)
            setCompanies(json);
            setCompaniesLoaded(true);
        })
        .catch((err) => {
           console.log(err.message);
        });
      }
      })   

  const cancelCreateUser = () => {
    <Navigate to={'/admin/'} />
    {navigate(0)}
  };
  
  const sendCreateUser = async () => {   
    const url = baseUrl+"user/register";
    var payload = {
      "userName": usernameReg,
      "password": passwordReg,
      "name": nameReg,
      "company": companyReg,
      "email": emailReg,
      "phone": phoneReg,
      "active": "1",
      "canSearch": canSearchReg,
      "canAddAsset": canAddAssetReg,
      "canEditAsset": canEditAssetReg,
      "canDeleteAsset": canDeleteAssetReg,
      "canInspectAsset": canInspectAssetReg,
      "canAddAttachment": canAddAttachmentReg,
      "canRegisterUser": canRegisterUserReg,
      "canUpdateUser": canUpdateUserReg,
      "canBulkAdd": canBulkAddReg,
      "canAddDefect": canAddDefect,
    }
    await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'username': localStorage.getItem('username'),
            'bToken': localStorage.getItem('bToken')
        },
        body: ( "json", JSON.stringify( payload ) )
      })
      .then((response) => response.json())
        .then((data) => {
          console.log(data)
          if( data.status === 200) {
          setMessage(data.message);
          setOpen(true);
          } else if (data.status === 401) {
            setMessage(data.message);
            setErrOpen(true);
          }
        })
        .catch((err) => {
          setMessage(err.message);
          setOpen(true);
        });
};

  
    return (
      <div className="admin">
        <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="confirmDeleteTitle">
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirmDeleteDesc">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Ok</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
        <React.Fragment>
      <Dialog
        open={errOpen}
        onClose={handleErrClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="addAccountError">
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="addAccountDesc">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleErrClose}>Ok</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
    

    { loginStatus && createNewUser && localStorage.getItem('canRegisterUser') === '1' ? (
        <form className="registration"
        // onSubmit={register}
        >
          <p>Register New User</p>
        <Box>
         <TextField 
        fullWidth
        required
          margin="dense"
          id="username" 
          label="Username" 
          variant="outlined" 
          type="text"  
          onChange={(e) => {
            setUsernameReg(e.target.value);
          }}
        />
     </Box>
        <Box>
         <TextField 
        fullWidth
        required
          margin="dense"
          id="password" 
          label="Password" 
          variant="outlined" 
          type="password"  
          onChange={(e) => {
            setPasswordReg(e.target.value);
          }}
        />
     </Box>
     <FormControl
          margin="dense"
     fullWidth>
     <InputLabel shrink htmlFor="companyTitle">
    Company *
  </InputLabel>
        <Select
          native
          required
          label="Company   *"
          inputProps={{
            id: 'companyTitle',
          }}
          fullWidth
          onChange={(e) => {
            setCompanyReg(e.target.value);
          }}>
          {
          companies.map((companies) => {
          return <option value={companies.company} key={companies.company}>{companies.description}</option>
        })
      }
  </Select>
     </FormControl>
        <Box>
         <TextField 
        fullWidth
        required
          margin="dense"
          id="name" 
          label="Name" 
          variant="outlined" 
          type="text"  
          onChange={(e) => {
            setNameReg(e.target.value);
          }}
        />
     </Box>
        <Box>
         <TextField 
        fullWidth
        required
          margin="dense"
          id="email" 
          label="E-Mail Address" 
          variant="outlined" 
          type="text"  
          onChange={(e) => {
            setEmailReg(e.target.value);
          }}
        />
     </Box>
        <Box>
         <TextField 
        fullWidth
        required
          margin="dense"
          id="phone" 
          label="Phone Number" 
          variant="outlined" 
          type="text"  
          onChange={(e) => {
            setPhoneReg(e.target.value);
          }}
        />
     </Box>
     <Box  sx={{ maxWidth: '85%' }}>
     <Typography left>Can Use Asset Search</Typography>
     <Slider 
  size="small"
  defaultValue={0}
  step={1}
  min={0}
  max={1}
  marks={marks}
  valueLabelDisplay="off"
  onChange={(e) => {
    setCanSearchReg(e.target.value);
  }}
/>
</Box>
     <Box  sx={{ maxWidth: '85%' }}>
     <Typography left>Can Add Defects</Typography>
     <Slider 
  size="small"
  defaultValue={0}
  step={1}
  min={0}
  max={1}
  marks={marks}
  valueLabelDisplay="off"
  onChange={(e) => {
    setCanAddDefect(e.target.value);
  }}
/>
</Box>
     <Box  sx={{ maxWidth: '85%' }}>
     <Typography left>Can Create New Assets</Typography>
     <Slider 
  size="small"
  defaultValue={0}
  step={1}
  min={0}
  max={1}
  marks={marks}
  valueLabelDisplay="off"
  onChange={(e) => {
    setCanAddAssetReg(e.target.value);
  }}
/>
</Box>
     <Box  sx={{ maxWidth: '85%' }}>
     <Typography left>Can Edit Existing Assets</Typography>
     <Slider 
  size="small"
  defaultValue={0}
  step={1}
  min={0}
  max={1}
  marks={marks}
  valueLabelDisplay="off"
  onChange={(e) => {
    setCanEditAssetReg(e.target.value);
  }}
/>
</Box>
     <Box  sx={{ maxWidth: '85%' }}>
     <Typography left>Can Delete Existing Assets</Typography>
     <Slider 
  size="small"
  defaultValue={0}
  step={1}
  min={0}
  max={1}
  marks={marks}
  valueLabelDisplay="off"
  onChange={(e) => {
    setCanDeleteAssetReg(e.target.value);
  }}
/>
</Box>
     <Box  sx={{ maxWidth: '85%' }}>
     <Typography left>Can Inspect Assets</Typography>
     <Slider 
  size="small"
  defaultValue={0}
  step={1}
  min={0}
  max={1}
  marks={marks}
  valueLabelDisplay="off"
  onChange={(e) => {
    setCanInspectAssetReg(e.target.value);
  }}
/>
</Box>
     <Box  sx={{ maxWidth: '85%' }}>
     <Typography left>Can Add Attachments to Assets</Typography>
     <Slider 
  size="small"
  defaultValue={0}
  step={1}
  min={0}
  max={1}
  marks={marks}
  valueLabelDisplay="off"
  onChange={(e) => {
    setCanAddAttachmentReg(e.target.value);
  }}
/>
</Box>
     <Box sx={{ maxWidth: '85%' }}>
     <Typography left>Can Create New Users</Typography>
     <Slider 
  size="small"
  defaultValue={0}
  step={1}
  min={0}
  max={1}
  marks={marks}
  valueLabelDisplay="off"
  onChange={(e) => {
    setCanRegisterUserReg(e.target.value);
  }}
/>
</Box>
     <Box  sx={{ maxWidth: '85%' }}>
     <Typography left>Can Edit/Update Users</Typography>
     <Slider 
  size="small"
  defaultValue={0}
  step={1}
  min={0}
  max={1}
  marks={marks}
  valueLabelDisplay="off"
  onChange={(e) => {
    setCanUpdateUserReg(e.target.value);
  }}
/>
</Box>
     <Box  sx={{ maxWidth: '85%' }}>
     <Typography left>Can Bulk Upload</Typography>
     <Slider 
  size="small"
  defaultValue={0}
  step={1}
  min={0}
  max={1}
  marks={marks}
  valueLabelDisplay="off"
  onChange={(e) => {
    setCanBulkAddReg(e.target.value);
  }}
/>
</Box>
          <Button variant="contained" margin="dense" onClick={sendCreateUser}> Register</Button>
          
          <Button variant="contained" margin="dense" onClick={cancelCreateUser}>Cancel User Creation</Button>
        </form>
        
        ) : null
      }
      <br />
      <br />
      </div>
    );
  }

export default Register