import React, { useEffect, useState } from "react";
import { baseUrl } from '../shared';
import Navbar from '../components/navBar';
import UserNavBar from '../components/userBar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AssetView from './assetView'
import Create from './create/createAsset'
import Admin from './admin'
import Logout from './logout'
import ResetPassword from './resetPassword'
import NoPage from './NoPage'
import Header from './header'
import Footer from './footer'
import './App.css';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function App() {

  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = useState('');

  const handleClose = () => {
    setOpen(false);
    logOutUser();
  };

  const logOutUser = () => {
    const url = baseUrl + 'user/logout';
fetch(url, {
  method: 'POST',
  headers: {
      'username': localStorage.getItem('username'),
      'bToken': localStorage.getItem('bToken')
  }})
.then((response) => response.json())
.then((data) => {
    console.log('logout',data);
    localStorage.clear();
    window.location.href='/'
})
.catch((err) => {
   console.log(err.message);
});
};

  const checkValidLogin = async () => {
    const url = baseUrl + 'user/validLogin';
      await fetch(url, {
        method: 'GET',
        headers: {
            'username': localStorage.getItem('username'),
            'bToken': localStorage.getItem('bToken')
        }})
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        if( data.status === "200") {
        localStorage.setItem("canSearch", data.canSearch)
        localStorage.setItem("canAddAsset", data.canAddAsset)
        localStorage.setItem("canEditAsset", data.canEditAsset)
        localStorage.setItem("canDeleteAsset", data.canDeleteAsset)
        localStorage.setItem("canInspectAsset", data.canInspectAsset)
        localStorage.setItem("canAddAttachment", data.canAddAttachment)
        localStorage.setItem("canRegisterUser", data.canRegisterUser)
        localStorage.setItem("canUpdateUser", data.canUpdateUser)
        localStorage.setItem("canBulkAdd", data.canBulkAdd)
        localStorage.setItem("canAddDefect", data.canAddDefect)
        } else if (data.status === 401) {
          setMessage(data.message);
          setOpen(true);
        }
      })
      .catch((err) => {
         console.log(err.message);
      }); 
  }

  useEffect(() => {
    if(localStorage.getItem('username') !== null){
      checkValidLogin()
    }
    }, []); 

 return (
  <>
  <React.Fragment>
  <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="confirmDeleteTitle">
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="confirmDeleteDesc">
        {message}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose}>
        OK
      </Button>
    </DialogActions>
  </Dialog>
</React.Fragment>
  <Header />
  <Router>
    <Navbar />
    <UserNavBar />
   <Routes>
     <Route>
       <Route path="/" exact element={<AssetView />} />
       <Route path="createAsset" element={<Create />} />
       <Route path="admin" element={<Admin />} />
       <Route path="logout" element={<Logout />} />
       <Route path="resetPassword" element={<ResetPassword />} />
       <Route path="*" element={<NoPage />} />
     </Route>
   </Routes>
 </Router>
 <Footer />
</>
 )
}

export default App