import React from "react";
import logo from './logo.png';

const Header = props => {

  return (
    <>
    <header >
      <img src={logo} className="App-logo"  alt="Asset Inspection and Management system" /> 
    </header>
  </>
  );
};
export default Header;
