import React, { useEffect, useState } from "react";
import { baseUrl } from '../shared';
import Login from '../components/login'
import FirstTab from "../components/tab-components/FirstTab";
import './App.css';
import Search from "../components/search";
import Edit from './edit/editAsset';
import Inspect from './edit/inspectAsset';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IconButton, TableHead } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import CancelIcon from '@mui/icons-material/Cancel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import moment from "moment";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Snackbar from '@mui/material/Snackbar';
import HelpIcon from '@mui/icons-material/Help';
import { WidthFull } from "@mui/icons-material";

const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
    var assetNoUrl = urlParams.get('assetNo')
    var catNoUrl = urlParams.get('catNo')
      console.log('Asset No: ', assetNoUrl);
      console.log('Cat No: ',catNoUrl)

function AssetView() {
  const [asset, setAsset] = useState();
  const [editing, setEditing] = useState(false);
  const [inspecting, setInspecting] = useState(false);
  const [addAttachment, setAddAttachment] = useState(false);
  var [inspectionHistory, setInspectionHistory] = useState();
  const [inspectionHistoryLoaded, setInspectionHistoryLoaded] = useState(false);
  const [uploadIdForDelete, setUploadIdForDelete] = useState('');
  let [openFunctions, setOpenFunctions] = useState(false);
  const [addDefect, setAddDefect] = useState(false);  
  const [resolveDefect, setResolveDefect] = useState(false);  
  const [defectHistoryLoaded, setDefectHistoryLoaded] = useState(false);
  const [openDefectLoaded, setOpenDefectLoaded] = useState(false);
  var [openDefects, setOpenDefects] = useState();
  const [closedDefectLoaded, setClosedDefectLoaded] = useState(false);
  var [closedDefects, setClosedDefects] = useState();
  const [priority, setPriority] = React.useState('');
  const [defectComments, setDefectComments] = React.useState('');
  const [defectResolution, setDefectResolution] = React.useState('');
  const [toastMessage, setToastMessage] = useState('');
  var [selectedDefectId, setSelectedDefectId] = useState('');
  const [documentHistoryLoaded, setDocumentHistoryLoaded] = useState(false);
  const [certificateDocumentLoaded, setCertificatesLoaded] = useState(false);
  const [partsAttachmentsLoaded, setPartsLoaded] = useState(false);
  const [photoAttachmentsLoaded, setPhotosLoaded] = useState(false);
  var [Certificates, setCertificates] = useState();
  var [Parts, setParts] = useState();
  var [Photos, setPhotos] = useState();
  const [contactOpen, setcontactOpen] = React.useState(false)

  
  const [toastState, setToastState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const { vertical, horizontal, toastOpen } = toastState;

  function clearAddAttachment() {
    setAddAttachment(false);
  }

  const handleChange = (event) => {
    setPriority(event.target.value);
  };

  const handleComments = (event) => {
    setDefectComments(event.target.value);
  };

  const handleResolution = (event) => {
    setDefectResolution(event.target.value);
  };

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastState({ ...toastState, toastOpen: false})
  }


  

  useEffect(()=> {
    console.log('Asset ',asset);
    console.log('Edit State:',editing);
    console.log('Inspecting State ',inspecting);
  });
  
  const lookUpAssetNo = async () => {
      const url = baseUrl + assetNoUrl;
      await fetch(url, {
        method: 'GET',
        headers: {
            'username': localStorage.getItem('username'),
            'bToken': localStorage.getItem('bToken')
        }})
      .then((response) => response.json())
      .then((data) => {
         setAsset(data[0]);
      })
      .catch((err) => {
         console.log(err.message);
      });
  };

  const lookUpCatNo = async () => {
      const url = baseUrl + 'catNo/' +catNoUrl;
      await fetch(url, {
        method: 'GET',
        headers: {
            'username': localStorage.getItem('username'),
            'bToken': localStorage.getItem('bToken')
        }})
      .then((response) => response.json())
      .then((data) => {
         setAsset(data[0]);
      })
      .catch((err) => {
         console.log(err.message);
      });
  };

  useEffect(() => {
    if(catNoUrl !== null) {
      lookUpCatNo()
    }
    if(assetNoUrl !== null) {
      lookUpAssetNo()
    }
  }, []);

  function showPartsAttachments() {
      const url = baseUrl + 'attachmentList/parts/' + asset.catNo;
      fetch(url, {
        method: 'GET',
        headers: {
            'username': localStorage.getItem('username'),
            'bToken': localStorage.getItem('bToken')
        }})
        .then((res) => {
          return res.json();
      }).then((json) => {
           setParts(json);
           setPartsLoaded(true);
      })
      .catch((err) => {
         console.log(err.message);
      });
    }

    function showCertificateAttachments() {
        const url = baseUrl + 'attachmentList/certificate/' + asset.catNo;
        fetch(url, {
          method: 'GET',
          headers: {
              'username': localStorage.getItem('username'),
              'bToken': localStorage.getItem('bToken')
          }})
          .then((res) => {
            return res.json();
        }).then((json) => {
             setCertificates(json);
             setCertificatesLoaded(true);
        })
        .catch((err) => {
           console.log(err.message);
        });
      }

      function showPhotoAttachments() {
          const url = baseUrl + 'attachmentList/photo/' + asset.catNo;
          fetch(url, {
            method: 'GET',
            headers: {
                'username': localStorage.getItem('username'),
                'bToken': localStorage.getItem('bToken')
            }})
            .then((res) => {
              return res.json();
          }).then((json) => {
               setPhotos(json);
               setPhotosLoaded(true);
          })
          .catch((err) => {
             console.log(err.message);
          });
        }

    function showFunctionList() {
      if(openFunctions) {
        setOpenFunctions(false)
      } else
      { setOpenFunctions(true)}
    }

    function showInspectionHistory() {
      const url = baseUrl + 'inspectHistory/' + asset.assetId;
      fetch(url, {
        method: 'GET',
        headers: {
            'username': localStorage.getItem('username'),
            'bToken': localStorage.getItem('bToken')
        }})
        .then((res) => {
          return res.json();
      }).then((json) => {
           setInspectionHistory(json);
           setInspectionHistoryLoaded(true);
      })
      .catch((err) => {
         console.log(err.message);
      });
    }

    function showDefectsList() {
      if(defectHistoryLoaded) {
        setDefectHistoryLoaded(false)
      } else
      { setDefectHistoryLoaded(true)}
    }

    function handleContactShow() {
      if(contactOpen) {
        setcontactOpen(false)
      } else
      setcontactOpen(true)
    }

    function showDocumentsList() {
      if(documentHistoryLoaded) {
        setDocumentHistoryLoaded(false)
      } else
      { setDocumentHistoryLoaded(true)}
    }

    function addNewDefect() {
      if(addDefect) {
        setAddDefect(false)
      } else
      { setAddDefect(true)}
    }

    function markDefectResolved(e) {
      if(resolveDefect) {
        setResolveDefect(false)
      } else
      { setSelectedDefectId(e)
        setResolveDefect(true)}
    }

    async function submitNewDefect(e) {
      if(priority === '' || defectComments === ''){
        console.log('fill in the fields!')
      } else {
        e.preventDefault();
        const defectInfo = {
          assetId: asset.assetId,
          defectComment: defectComments,
          priority: priority,
        };
        const url = baseUrl + 'defect/new/';
        await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'username': localStorage.getItem('username'),
                'bToken': localStorage.getItem('bToken')
            },
            body: JSON.stringify(defectInfo),
        })
            .then((response) => response.json())
              .then((data) => {
                console.log(data[0])
                if( data[0].status === 200) {
                  setAddDefect(false)
                setToastMessage(data[0].message);
                setToastState({ ...toastState, toastOpen: true, vertical: 'top', horizontal: 'center' })
                } else if (data[0].status === 401) {
                  setToastMessage(data[0].message);
                  setToastState({ ...toastState, toastOpen: true, vertical: 'top', horizontal: 'center' })
                }
              })
              .catch((err) => {
                setToastMessage(err.message);
                setToastState({ ...toastState, toastOpen: true, vertical: 'top', horizontal: 'center' })
              }); 
        console.log('priority: ',priority)
      console.log('comments: ',defectComments)
      setPriority('')
      setDefectComments('')
      }
    }

    async function resolveExistingDefect(e) {
      if(defectResolution === ''){
        console.log('fill in the fields!')
      } else {
        e.preventDefault();
        const defectInfo = {
          assetId: asset.assetId,
          defectId: selectedDefectId,
          resolvedComment: defectResolution,
        };
        const url = baseUrl + 'defect/resolved/';
        await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'username': localStorage.getItem('username'),
                'bToken': localStorage.getItem('bToken')
            },
            body: JSON.stringify(defectInfo),
        })
            .then((response) => response.json())
              .then((data) => {
                console.log(data[0])
                if( data[0].status === 200) {
                  setResolveDefect(false)
                setToastMessage(data[0].message);
                setToastState({ ...toastState, toastOpen: true, vertical: 'top', horizontal: 'center' })
                showOpenDefects();
                } else if (data[0].status === 401) {
                  setToastMessage(data[0].message);
                  setToastState({ ...toastState, toastOpen: true, vertical: 'top', horizontal: 'center' })
                }
              })
              .catch((err) => {
                setToastMessage(err.message);
                setToastState({ ...toastState, toastOpen: true, vertical: 'top', horizontal: 'center' })
              }); 
        console.log('priority: ',priority)
      console.log('comments: ',defectComments)
      setPriority('')
      setDefectComments('')
      }
    }

    function showOpenDefects() {
      const url = baseUrl + 'defect/open/'+asset.assetId;
      fetch(url, {
        method: 'GET',
        headers: {
            'username': localStorage.getItem('username'),
            'bToken': localStorage.getItem('bToken')
        }
      })
        .then((res) => {
          return res.json();
      }).then((json) => {
            console.log('open defects:',json);
           setOpenDefects(json);
           setOpenDefectLoaded(true);
      })
      .catch((err) => {
         console.log(err.message);
      });
    }

    function hideOpenDefects() {
      setOpenDefects();
      setOpenDefectLoaded(false)
    }

    function showClosedDefects() {
      const url = baseUrl + 'defect/closed/'+asset.assetId;
      fetch(url, {
        method: 'GET',
        headers: {
            'username': localStorage.getItem('username'),
            'bToken': localStorage.getItem('bToken')
        }
      })
        .then((res) => {
          return res.json();
      }).then((json) => {
           setClosedDefects(json);
           setClosedDefectLoaded(true);
      })
      .catch((err) => {
         console.log(err.message);
      });
    }

    function hideClosedDefects() {
      setClosedDefects();
      setClosedDefectLoaded(false)
    }

 
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = uploadId => {
      setUploadIdForDelete(uploadId)
      setOpen(true);
    };

  const handleClose = () => {
    setUploadIdForDelete('')
    setOpen(false);
  };

    function delAttachment() {
      const url = baseUrl + 'uploadDel';
      fetch(url, {
        method: 'POST',
        headers: {
            'username': localStorage.getItem('username'),
            'bToken': localStorage.getItem('bToken'),
            'uploadId': uploadIdForDelete
        }}) 
        .then((res) => {
          return res.json();
      }).then((json) => {
           setOpen(false)
           setUploadIdForDelete('')
      })
      .catch((err) => {
         console.log(err.message);
      });
    }

    function hideCertificateDocuments() {
      setCertificatesLoaded(false);
    }

    function hidePartsDocuments() {
      setPartsLoaded(false);
    }

    function hidePhotoDocuments() {
      setPhotosLoaded(false);
    }

    function hideInspectionHistory() {
      setInspectionHistoryLoaded(false);
    }

  return (
    
    <div className="assetView">
      <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="confirmDeleteTitle">
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirmDeleteDesc">
            Are you sure you want to delete the Attachment?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={delAttachment}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>

    <React.Fragment>
      <Dialog
        open={contactOpen}
        onClose={!contactOpen}
      >
        <DialogTitle>
        Site Contact Information
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="showContactDetailsDesc">
            Contact Name: {asset ? asset.siteContact : ''} <br />
            Contact Email: {asset ? asset.siteContactEmail : ''} <br />
            Contact Phone: {asset ? asset.siteContactPhone : ''} <br />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleContactShow}>OK</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
    
    <React.Fragment>
      <Dialog
        open={addDefect}
        onClose={addNewDefect}
      >
        <DialogTitle>Add New Defect</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please select the Priority of the defect found and enter your issues found below.
          </DialogContentText>
          <br />
          <InputLabel id="priority">Select Priority *</InputLabel>
          <Select
            labelId="priority"
            id="priority"
            value={priority}
            label="priority"
            variant="standard"
            margin="dense"
            onChange={handleChange}
            >
              <MenuItem value={'Low'}>Low</MenuItem>
              <MenuItem value={'Medium'}>Medium</MenuItem>
              <MenuItem value={'High'}>High</MenuItem>
          </Select>
          <TextField
            autoFocus
            multiline
            required
            margin="dense"
            id="name"
            name="email"
            label="Defect Found"
            fullWidth
            variant="standard"
            onChange={handleComments}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={addNewDefect}>Cancel</Button>
          <Button onClick={submitNewDefect}>Add</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>

    <React.Fragment>
      <Dialog
        open={resolveDefect}
        onClose={markDefectResolved}
      >
        <DialogTitle>Resolve Defect</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter resolution for Defect to mark as closed.
          </DialogContentText>
          <br />
          <TextField
            autoFocus
            multiline
            required
            margin="dense"
            id="name"
            name="defectResolution"
            label="Defect Resolution"
            fullWidth
            variant="standard"
            onChange={handleResolution}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={markDefectResolved}>Cancel</Button>
          <Button onClick={resolveExistingDefect}>Resolve</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>

    <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={toastOpen}
        autoHideDuration={2500}
        onClose={handleToastClose}
        message={toastMessage}
        key={vertical + horizontal}
      />

    {localStorage.getItem('username') !== null ? (
  null
): <div>
< Login />
</div>}

<br></br>

{!asset && !editing && !inspecting && localStorage.getItem('canSearch') === '1' ? (
  <div>
    < Search />
  </div>
): null}


    { asset && !editing && !inspecting ? (
      <div>  
        { asset && !addAttachment ? (
 <TableContainer component={Paper}>
      <Table sx={{ minWidth: 300 }} aria-label="simple table" margin="dense">
        <TableBody>
          <TableRow><TableCell align="left">Catalogue Number</TableCell><TableCell align="right" className="ucase">{asset.catNo}</TableCell></TableRow>
          <TableRow><TableCell align="left">Asset Number</TableCell><TableCell align="right" className="ucase">{asset.assetId}</TableCell></TableRow>
          <TableRow><TableCell align="left">Customer</TableCell><TableCell align="right">{asset.customer}</TableCell></TableRow>
          <TableRow><TableCell align="left">Type</TableCell><TableCell align="right">{asset.type}</TableCell></TableRow>
          <TableRow><TableCell align="left">Site Location</TableCell><TableCell align="right">{asset.siteLocation}  </TableCell></TableRow>
          <TableRow><TableCell align="left">Site Contact</TableCell><TableCell align="right">{asset.siteContact} <IconButton margin="dense" variant="contained" onClick={handleContactShow}><HelpIcon /></IconButton></TableCell></TableRow>
          <TableRow><TableCell align="left">Item</TableCell><TableCell align="right">{asset.item}</TableCell></TableRow>
          <TableRow><TableCell align="left">Manufacturer</TableCell><TableCell align="right">{asset.manufacturer}</TableCell></TableRow>
          <TableRow><TableCell align="left">Model</TableCell><TableCell align="right">{asset.model}</TableCell></TableRow>
          <TableRow><TableCell align="left">Serial Number</TableCell><TableCell align="right">{asset.serialNo}</TableCell></TableRow>
          <TableRow><TableCell align="left">Max Height</TableCell><TableCell align="right">{asset.maxHeight}</TableCell></TableRow>
          <TableRow><TableCell align="left">Capacity</TableCell><TableCell align="right">{asset.capacity}</TableCell></TableRow>
          <TableRow><TableCell align="left">Build Date</TableCell><TableCell align="right">{(asset.buildDate === null || asset.buildDate === '-') ? '-':moment(asset.buildDate).format('DD-MM-YYYY')}</TableCell></TableRow>
          <TableRow><TableCell align="left">Calibration Expiry Date</TableCell><TableCell align="right">{(asset.expiringCalibrationDate === null || asset.expiringCalibrationDate === '-') ? '-':moment(asset.expiringCalibrationDate).format('DD-MM-YYYY')}</TableCell></TableRow>
          <TableRow><TableCell align="left">Last Inspection Result</TableCell><TableCell align="right">{asset.inspectionStatus}</TableCell></TableRow>
          <TableRow><TableCell align="left">Last Inspection Date</TableCell><TableCell align="right">{(asset.lastInspectionDate === null || asset.lastInspectionDate === '-' ) ? '-':moment(asset.lastInspectionDate).format('DD-MM-YYYY')}</TableCell></TableRow>
          <TableRow><TableCell align="left">Next Inspection Due</TableCell><TableCell align="right">{(asset.nextInspectionDate === null || asset.nextInspectionDate === '-') ? '-':moment(asset.nextInspectionDate).format('DD-MM-YYYY')}</TableCell></TableRow>
          <TableRow><TableCell align="left">Comments</TableCell><TableCell align="right">{asset.inspectionReason}</TableCell></TableRow>
          </TableBody>
      </Table>
    </TableContainer>
    ):null}

{ !addAttachment && asset ? (
<div className="mb-2">
                        
                        <TableContainer component={Paper}>
                              <Table sx={{ minWidth: 300 }} aria-label="simple table" margin="dense">
                                <TableHead>
                                  <TableCell align="center">
                                    <h2>Inspection History{ asset && !inspectionHistoryLoaded ? ( <IconButton onClick={showInspectionHistory}><ExpandMoreIcon /></IconButton>) : (<IconButton  margin="dense" variant="contained" onClick={hideInspectionHistory}><ExpandLessIcon /></IconButton>)}</h2> 
                                  </TableCell>
                                </TableHead>
                              {asset && inspectionHistoryLoaded ? (
                              <TableBody>
                                              {inspectionHistory.map((list, index) => (
                                                <TableRow key={index}  style={{backgroundColor:'#FAFAFA'}}>
                                                    <TableCell align="center"><span style={{fontWeight: "bold", fontFamily: 'arial'}}>Inspection Date:</span> {moment(list.updatedAt).format('DD-MM-YYYY hh:mm A')} <br /><span style={{fontWeight: "bold", fontFamily: 'arial'}}>Status:</span> {list.inspectionStatus}<br /> <span style={{fontWeight: "bold", fontFamily: 'arial'}}>Inspected By:</span> {list.updatedBy}<br /><span style={{fontWeight: "bold", fontFamily: 'arial'}}>Notes:</span> {list.inspectionReason}</TableCell>
                                                </TableRow>
                                              ))}
                                            </TableBody>):null}
                              </Table>
                            </TableContainer>
                              </div>
): null}

    { !addAttachment && asset ? (
  <div className="mb-2">
<TableContainer component={Paper}>
      <Table sx={{ minWidth: 300 }} aria-label="simple table" margin="dense">

      <TableHead>
          <TableCell align="center">
            <h2>Defects{ asset ? ( <IconButton onClick={showDefectsList}><ExpandMoreIcon /></IconButton>) : (<IconButton margin="dense" variant="contained" onClick={showDefectsList}><ExpandLessIcon /></IconButton>)}</h2> 
          </TableCell>
        </TableHead>
        { defectHistoryLoaded ? (
                    <TableBody>
                    { !addAttachment && asset && (localStorage.getItem('canAddDefect') === '1' || (localStorage.getItem('canInspectAsset') === '1') ? (
                    <TableRow style={{backgroundColor:'#ffffc4'}}>
                        <TableCell align="center">
                            <Button
                                margin="dense"
                                variant="contained"
                                style={{minWidth: '100%',backgroundColor:'#ffff14', color:'#000000'}}
                                onClick={(e) => {
                                    setAddDefect(true);
                                }}
                            >
                                Report Defect
                            </Button>
                        </TableCell>
                    </TableRow>
                  ):null)}
                        <TableRow style={{backgroundColor:'#ffffc4'}}>
                            <TableCell align="center"><h3>Open Defects{ !openDefectLoaded ? ( <IconButton onClick={showOpenDefects}><ExpandMoreIcon /></IconButton>) : (<IconButton margin="dense" variant="contained" onClick={hideOpenDefects}><ExpandLessIcon /></IconButton>)}</h3> </TableCell>        
                        </TableRow>
                                {openDefectLoaded ? (
                                  <TableRow style={{backgroundColor:'#ffffeb'}}>
                                  {openDefects.map((list, index) => (
                                                <TableRow key={index}>
                                                    <TableCell align="center"><span style={{fontWeight: "bold", fontFamily: 'arial'}}>Defect Added:</span> {moment(list.createdAt).format('DD-MM-YYYY hh:mm A')} <br /><span style={{fontWeight: "bold", fontFamily: 'arial'}}>Added By:</span> {list.addedBy}<br /> <span style={{fontWeight: "bold", fontFamily: 'arial'}}>Defect Found:</span> {list.defectComment} </TableCell> { localStorage.getItem('canInspectAsset') === '1' ? (<TableCell><IconButton margin="dense" variant="contained" onClick = {() => {markDefectResolved(list.id)}} ><Tooltip title="Mark defect as Complete"><CheckCircleOutlineIcon /></Tooltip></IconButton></TableCell>): null}
                                                </TableRow>
                                              ))}
                                  </TableRow>):null}
                        <TableRow style={{backgroundColor:'#ffffc4'}}>
                            <TableCell align="center"><h3>Closed Defects{ !closedDefectLoaded ? ( <IconButton onClick={showClosedDefects}><ExpandMoreIcon /></IconButton>) : (<IconButton margin="dense" variant="contained" onClick={hideClosedDefects}><ExpandLessIcon /></IconButton>)}</h3> </TableCell>
                        </TableRow>
                                {closedDefectLoaded ? (
                                  <TableRow style={{backgroundColor:'#ffffeb'}}>
                                  {closedDefects.map((list, index) => (
                                                <TableRow key={index}>
                                                    <TableCell align="center"><span style={{fontWeight: "bold", fontFamily: 'arial'}}>Defect Added:</span> {moment(list.createdAt).format('DD-MM-YYYY hh:mm A')} <br /><span style={{fontWeight: "bold", fontFamily: 'arial'}}>Added By:</span> {list.addedBy}<br /> <span style={{fontWeight: "bold", fontFamily: 'arial'}}>Defect Found:</span> {list.defectComment}<br /><span style={{fontWeight: "bold", fontFamily: 'arial'}}>Defect Resolved:</span> {moment(list.resolvedAt).format('DD-MM-YYYY hh:mm A')} <br /><span style={{fontWeight: "bold", fontFamily: 'arial'}}>Resolved By:</span> {list.resolvedBy}<br /> <span style={{fontWeight: "bold", fontFamily: 'arial'}}>Defect Resolution:</span> {list.resolvedComment}</TableCell>
                                                </TableRow>
                                              ))}
                                  </TableRow>):null}
                    </TableBody>):null}

      </Table>
    </TableContainer>
      </div>
): null}

    { !addAttachment && asset ? (
  <div className="mb-2">
<TableContainer component={Paper}>
      <Table sx={{ minWidth: 300 }} aria-label="simple table" margin="dense">

      <TableHead>
          <TableCell align="center">
            <h2>Documents{ asset ? ( <IconButton onClick={showDocumentsList}><ExpandMoreIcon /></IconButton>) : (<IconButton margin="dense" variant="contained" onClick={showDocumentsList}><ExpandLessIcon /></IconButton>)}</h2> 
          </TableCell>
        </TableHead>
        { documentHistoryLoaded ? (
                    <TableBody>
                        <TableRow style={{backgroundColor:'#dcfffc'}}>
                            <TableCell align="center"><h3>Certificates{ !certificateDocumentLoaded ? ( <IconButton onClick={showCertificateAttachments}><ExpandMoreIcon /></IconButton>) : (<IconButton margin="dense" variant="contained" onClick={hideCertificateDocuments}><ExpandLessIcon /></IconButton>)}</h3> </TableCell>        
                        </TableRow>
                                {certificateDocumentLoaded ? (
                                  <TableRow style={{backgroundColor:'#f0fffe'}}>
                                  {Certificates.map((list, index) => (
                                    <TableRow key={index} style={{backgroundColor:'#f0fffe'}}>
                                        <TableCell align="center" width={'100%'}><a href={list.imageUrl}>{list.image_name}</a> </TableCell> <TableCell align="right"> { localStorage.getItem('canAddAttachment') === '1' ? (<IconButton margin="dense" variant="contained" onClick = {() => {handleClickOpen(list.uploadId)}} ><Tooltip title="Delete Attachment"><CancelIcon /></Tooltip></IconButton>): null}</TableCell>
                                    </TableRow>
                                              ))}
                                  </TableRow>):null}
                        <TableRow style={{backgroundColor:'#dcfffc'}}>
                            <TableCell align="center" ><h3>Parts List{ !partsAttachmentsLoaded ? ( <IconButton onClick={showPartsAttachments}><ExpandMoreIcon /></IconButton>) : (<IconButton margin="dense" variant="contained" onClick={hidePartsDocuments}><ExpandLessIcon /></IconButton>)}</h3> </TableCell>
                        </TableRow>
                                {partsAttachmentsLoaded ? (
                                  <TableRow style={{backgroundColor:'#f0fffe'}}>
                                  {Parts.map((list, index) => (
                        <TableRow key={index} style={{backgroundColor:'#f0fffe'}}>
                            <TableCell align="center" width={'100%'}><a href={list.imageUrl}>{list.image_name}</a> </TableCell> <TableCell align="right">{ localStorage.getItem('canAddAttachment') === '1' ? (<IconButton margin="dense" variant="contained" onClick = {() => {handleClickOpen(list.uploadId)}} ><Tooltip title="Delete Attachment"><CancelIcon /></Tooltip></IconButton>): null}</TableCell>
                        </TableRow>
                                              ))}
                                  </TableRow>):null}
                        <TableRow style={{backgroundColor:'#dcfffc'}}>
                            <TableCell align="center"><h3>Photos{ !photoAttachmentsLoaded ? ( <IconButton onClick={showPhotoAttachments}><ExpandMoreIcon /></IconButton>) : (<IconButton margin="dense" variant="contained" onClick={hidePhotoDocuments}><ExpandLessIcon /></IconButton>)}</h3> </TableCell>
                        </TableRow>
                                {photoAttachmentsLoaded ? (
                                  <TableRow style={{backgroundColor:'#f0fffe'}}>
                                  {Photos.map((list, index) => (
                        <TableRow key={index} style={{backgroundColor:'#f0fffe'}}>
                            <TableCell align="center" width={'100%'}><a href={list.imageUrl}>{list.image_name}</a> </TableCell> <TableCell align="right"> { localStorage.getItem('canAddAttachment') === '1' ? (<IconButton margin="dense" variant="contained" onClick = {() => {handleClickOpen(list.uploadId)}} ><Tooltip title="Delete Attachment"><CancelIcon /></Tooltip></IconButton>): null}</TableCell>
                        </TableRow>
                                              ))}
                                  </TableRow>):null}
                    </TableBody>):null}

      </Table>
    </TableContainer>
      </div>
): null}

{ !addAttachment && asset && (localStorage.getItem('canInspectAsset') === '1' || localStorage.getItem('canEditAsset') === '1' || localStorage.getItem('canAddAttachment') === '1' ) ? (
<div className="mb-2">
                        
                        <TableContainer component={Paper}>
                              <Table sx={{ minWidth: 300 }} aria-label="simple table" margin="dense">
                                <TableHead>
                                  <TableCell align="center">
                                    <h2>Auditor{ asset && !inspectionHistoryLoaded ? ( <IconButton onClick={showFunctionList}><ExpandMoreIcon /></IconButton>) : (<IconButton  margin="dense" variant="contained" onClick={showFunctionList}><ExpandLessIcon /></IconButton>)}</h2> 
                                  </TableCell>
                                </TableHead>
                                { openFunctions ? (
                              <TableBody>
                                    { !addAttachment && asset && (localStorage.getItem('canInspectAsset') === '1' ? (
                                    <TableRow style={{backgroundColor:'#FAFAFA'}}>
                                        <TableCell align="center">
                                            <Button
                                                margin="dense"
                                                variant="contained"
                                                style={{minWidth: '100%'}}
                                                onClick={(e) => {
                                                    setInspecting(true);
                                                }}
                                            >
                                                Inspect Asset
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                  ):null)}
                                  { !addAttachment && asset && localStorage.getItem('canEditAsset') === '1' ? (
                                    <TableRow style={{backgroundColor:'#FAFAFA'}}>
                                        <TableCell align="center">
                                                              <Button
                                                                  margin="dense"
                                                                  variant="contained"
                                                                  style={{minWidth: '100%'}}
                                                                  onClick={(e) => {
                                                                      setEditing(true);
                                                                  }}
                                                              >
                                                                  Edit Item
                                                              </Button>
                                        </TableCell>
                                    </TableRow>
                                  ): null}
                                  { !addAttachment && asset && localStorage.getItem('canAddAttachment') === '1' ? (
                                    <TableRow>
                                        <TableCell align="center">
                                                              <Button
                                                                  margin="dense"
                                                                  variant="contained"
                                                                  style={{minWidth: '100%'}}
                                                                  onClick={(e) => {
                                                                      setAddAttachment(true);
                                                                  }}
                                                              >
                                                                  Add Document
                                                              </Button>
                                        </TableCell>
                                    </TableRow>
                                  ): null}
                               </TableBody>):null}
                              </Table>
                            </TableContainer>
                              </div>
): null}

 {addAttachment && asset ? (
  <div className="modalOuter">
  <div className="modalBox">
      <div className="outlet">
        <br />
  <Button variant="contained" margin="dense" sx={{ maxWidth: '25%', marginLeft: "auto" }} onClick={clearAddAttachment}>Cancel Upload</Button>
  <FirstTab  foundAsset={asset}/>
          </div>
        </div>
      </div>
 ): null}
 
{ !addAttachment && asset && localStorage.getItem('canSearch') === '1' ? (
          <div className="mb-2">
                            <Button
                                margin="dense"
                                variant="contained"
                                style={{minWidth: '100%'}}
                                onClick={(e) => {
                                    window.location.href='/'
                                }}
                            >Home
                            </Button>
          </div>
          ): null}
          

  
  </div>
    ): null}

{ !addAttachment && editing && localStorage.getItem('canEditAsset') === '1' ? (        
<Edit 
foundAsset={asset}/>
): null}

{ !addAttachment && inspecting && localStorage.getItem('canInspectAsset') === '1' ? (        
<Inspect 
foundAsset={asset}/>
): null}
<br />
<br />
  </div>
);
}
export default AssetView;